import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: '/',
      redirect: '/home'
    },

    {
      path: '/home',//首页
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/login/:id',//登录
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/perfect-info',
      name: 'perfect-info',
      component: () => import(/* webpackChunkName: "login" */ '@/views/PerfectInfo.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/dashboard',//管理页面
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Dashboard.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/clbum',//班级管理
      name: 'clbum',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Clbum.vue'),
      meta: {
        index: 2
      }
    },

    {
      path: '/share-clbum',//班级分享,报名连接
      name: 'share-clbum',
      component: () => import(/* webpackChunkName: "home" */ '@/views/ShareClbum.vue'),
      meta: {
        index: 2
      }
    },

    {
      path: '/student-list',//班级下学生列表
      name: 'student-list',
      component: () => import(/* webpackChunkName: "home" */ '@/views/StudentList.vue'),
      meta: {
        index: 3
      }
    },

    {
      path: '/enroll',//报名管理
      name: 'enroll',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Enroll.vue'),
      meta: {
        index: 2
      }
    },

    {
      path: '/payment',//缴费管理
      name: 'payment',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Payment.vue'),
      meta: {
        index: 2
      }
    },

    {
      path: '/photo',//图片管理
      name: 'photo',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Photo.vue'),
      meta: {
        index: 2
      }
    },

    {
      path: '/evaluation',//本校测评管理
      name: 'evaluation',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Evaluation.vue'),
      meta: {
        index: 2
      }
    },

    {
      path: '/grade',//成绩管理
      name: 'grade',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Grade.vue'),
      meta: {
        index: 2
      }
    },

    {
      path: '/grade-analyse',//成绩分析
      name: 'grade-analyse',
      component: () => import(/* webpackChunkName: "home" */ '@/views/GradeAnalyse.vue'),
      meta: {
        index: 2
      }
    },

    {
      path: '/papers-search',//试卷查询
      name: 'papers-search',
      component: () => import(/* webpackChunkName: "home" */ '@/views/PapersSearch.vue'),
      meta: {
        index: 2
      }
    },

    {
      path: '/detection',//检测选择页面
      name: 'detection',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Detection.vue'),
      meta: {
        index: 3
      }
    },
    {
      path: '/clbum-confirm',//班级确认页面
      name: 'clbum-confirm',
      component: () => import(/* webpackChunkName: "home" */ '@/views/ClbumConfirm.vue'),
      meta: {
        index: 4
      }
    },

    {
      path: '/dai-enroll',//代报名
      name: 'dai-enroll',
      component: () => import(/* webpackChunkName: "home" */ '@/views/DaiEnroll.vue'),
      meta: {
        index: 3
      }
    },

    
    {
      path: '/create-order',//创建订单
      name: 'create-order',
      component: () => import(/* webpackChunkName: "home" */ '@/views/CreateOrder.vue'),
      meta: {
        index: 4
      }
    },

    {
      path: '/edit-photo',//考生照片重拍
      name: 'edit-photo',
      component: () => import(/* webpackChunkName: "home" */ '@/views/EditPhoto.vue'),
      meta: {
        index: 4
      }
    },

    {
      path: '/setll-account',//结算金额
      name: 'setll-account',
      component: () => import(/* webpackChunkName: "home" */ '@/views/SetllAccount.vue'),
      meta: {
        index: 4
      }
    },

    {
      path: '/add-evaluation',//结算金额
      name: 'add-evaluation',
      component: () => import(/* webpackChunkName: "home" */ '@/views/AddEvaluation.vue'),
      meta: {
        index: 4
      }
    },

    {
      path: '/common-student',//考生公共页面(检测信息.补报检测,缴费管理.....)
      name: 'common-student',
      component: () => import(/* webpackChunkName: "home" */ '@/views/CommonStudent.vue'),
      meta: {
        index: 4
      }
    },

    {
      path: '/stage-goal',//阶段目标
      name: 'stage-goal',
      component: () => import(/* webpackChunkName: "home" */ '@/views/StageGoal.vue'),
      meta: {
        index: 4
      }
    },

    {
      path: '/ultimate-goal',//最终目标
      name: 'ultimate-goal',
      component: () => import(/* webpackChunkName: "home" */ '@/views/UltimateGoal.vue'),
      meta: {
        index: 4
      }
    },

    {
      path: '/admission-ticket',//我的准考证
      name: 'admission-ticket',
      component: () => import(/* webpackChunkName: "home" */ '@/views/AdmissionTicket.vue'),
      meta: {
        index: 4
      }
    },

    {
      path: '/grade-record',//成绩录入
      name: 'grade-record',
      component: () => import(/* webpackChunkName: "home" */ '@/views/GradeRecord.vue'),
      meta: {
        index: 4
      }
    },

    {
      path: '/blank-page',//空白页面,用于中转
      name: 'blank-page',
      component: () => import(/* webpackChunkName: "home" */ '@/views/BlankPage.vue'),
      meta: {
        index: 4
      }
    },

    {
      path: '/grade-graph',//考生成绩图表
      name: 'grade-graph',
      component: () => import(/* webpackChunkName: "home" */ '@/views/GradeGraph.vue'),
      meta: {
        index: 4
      }
    },
    {
      path: '/good-paper',//优秀试卷列表
      name: 'good-paper',
      component: () => import(/* webpackChunkName: "home" */ '@/views/GoodPaper.vue'),
      meta: {
        index: 4
      }
    },
    {
      path: '/marking-login',//优秀试卷老师登录
      name: 'marking-login',
      component: () => import(/* webpackChunkName: "home" */ '@/views/MarkingLogin.vue'),
      meta: {
        index: 4
      }
    },
    {
      path: '/upload-exam',//试卷上传
      name: 'upload-exam',
      component: () => import(/* webpackChunkName: "home" */ '@/views/UploadExam.vue'),
      meta: {
        index: 4
      }
    },
    {
      path: '/forget-pwd',//考生公共页面(检测信息.补报检测,缴费管理.....)
      name: 'forget-pwd',
      component: () => import(/* webpackChunkName: "home" */ '@/views/ForgetPwd.vue'),
      meta: {
        index: 4
      }
    },
    {
      path: '/user-protocol',//用户协议介绍
      name: 'user-protocol',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Userprotocol.vue'),
      meta: {
        index: 4
      }
    },
    {
      path: '/privacy-protocol',//隐私协议介绍
      name: 'privacy-protocol',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Privacyprotocol.vue'),
      meta: {
        index: 4
      }
    },
  ]
})
router.beforeEach((to, from, next) => {
  let spliUlr = ['/share-clbum','/create-order','/common-student','/grade-graph','/upload-exam'];//ios进入页面,重新加载解决微信ios调用失败问题
  if (to.path !== location.pathname && spliUlr.includes(to.path)) {//修复iOS版微信HTML5 History兼容性问题
      location.assign(to.fullPath)
    } else {
      next()
    }
});
export default router